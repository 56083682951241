import React from 'react';
import { WidgetProps, useEnvironment } from '@wix/yoshi-flow-editor';
import { CommonProps, ListProps } from '../../../types/common';
import { PlanListSettingsAdapter } from '../../PlanList/PlanListSettingsAdapter';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import List from './List';
import { WidgetContext } from './WidgetContext';

type PlanListPageWidgetProps = CommonProps & ListProps;

const PlanListPageWidget: React.FC<WidgetProps<PlanListPageWidgetProps>> = (props) => {
  const { navigateToHomePage } = props;
  const { isMobile } = useEnvironment();
  const width = props.host.dimensions.width ?? (isMobile ? 320 : 980);

  const emptyState = React.useMemo(
    () => ({ isInWidget: false, onClick: () => navigateToHomePage() }),
    [navigateToHomePage],
  );

  return (
    <WidgetContext
      settingsAdapterClass={PlanListSettingsAdapter}
      settingsParams={settingsParams}
      stylesParams={stylesParams}
      locale={props.locale}
      width={width}
    >
      <List emptyState={emptyState} {...props} />
    </WidgetContext>
  );
};

export default PlanListPageWidget;
